import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

//Icons
import DownloadIcon from "../assets/icons/download.svg"

// Logos
import LogosVL from "../assets/secure-payments-precessing-vl.svg"
import LogosVD from "../assets/secure-payments-precessing-vd.svg"
import LogosHL from "../assets/secure-payments-precessing-hl.svg"
import LogosHD from "../assets/secure-payments-precessing-hd.svg"
import LogosBLD from "../assets/secure-payments-precessing-bld.svg"
import TwispayLogo from "../assets/twispay-logo.svg"
import TwispayWhiteLogo from "../assets/twispay-logo-white.svg"

// Styles
import "../styles/pages/logos-resources.scss"

const LogosResourcesPage = () => {
  const types = [
    {
      title: "Vertical",
      lightLogo: LogosVL,
      darkLogo: LogosVD,
    },
    {
      title: "Horizontal",
      lightLogo: LogosHL,
      darkLogo: LogosHD,
    },
    {
      title: "Biscuit",
      lightLogo: LogosBLD,
      darkLogo: LogosBLD,
    },
  ]

  const kits = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          name: { regex: "/twispay-logo-kit-[a-z]/" }
          extension: { eq: "zip" }
        }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)
  return (
    <Layout
      stripeClass="is-transparent"
      pageName="logos-resources"
      hasLightBackground
    >
      <SEO title="Twispay, Visa and Mastercard logos for your website" />
      <section className="section is-hero">
        <div className="container">
          <h1 className="has-text-centered title is-2 has-margin-bottom-6">
            Logos & Resources
          </h1>
          <div className="columns is-centered">
            <div className="column is-three-fifths">
              <div className="is-card main-card">
                <h2 className="title is-4 is-spaced">Merchant Badges</h2>
                <p className="subtitle is-5 has-text-grey-light has-margin-bottom-6">
                  The blue logo works best on light backgrounds. The white logo
                  is designed for dark backgrounds.
                </p>
                {types.map(type => (
                  <div key={type.title} className="has-margin-bottom-6">
                    <h3 className="title is-4 has-text-weight-bold">
                      {type.title}
                    </h3>
                    <div className="columns">
                      <div className="column">
                        <div className="logo-wrapper">
                          <a
                            href={type.lightLogo}
                            className="download-wrapper"
                            download={`${type.title} Light Logo`}
                          >
                            <span className="icon is-large has-text-violet">
                              <DownloadIcon />
                            </span>
                          </a>
                          <img
                            src={type.lightLogo}
                            alt={`${type.title} Light Logo`}
                          />
                        </div>
                      </div>
                      <div className="column">
                        <div className="logo-wrapper has-background-default">
                          <a
                            href={type.darkLogo}
                            className="download-wrapper"
                            download={`${type.title} Dark Logo`}
                          >
                            <span className="icon is-large has-text-white">
                              <DownloadIcon />
                            </span>
                          </a>
                          <img
                            src={type.darkLogo}
                            alt={`${type.title} Dark Logo`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <h2 className="title is-4 is-spaced">Twispay Logo</h2>
                <p className="subtitle is-5 has-text-grey-light has-margin-bottom-6">
                  The blue logo works best on light backgrounds. The white logo
                  is designed for dark backgrounds.
                </p>
                <div className="has-margin-bottom-6">
                  <div className="columns">
                    <div className="column">
                      <div className="logo-wrapper">
                        <a
                          href={TwispayLogo}
                          className="download-wrapper"
                          download="Twispay Light Logo"
                        >
                          <span className="icon is-large has-text-violet">
                            <DownloadIcon />
                          </span>
                        </a>
                        <img src={TwispayLogo} alt="Twispay Light Logo" />
                      </div>
                    </div>
                    <div className="column">
                      <div className="logo-wrapper has-background-primary">
                        <a
                          href={TwispayWhiteLogo}
                          className="download-wrapper"
                          download="Twispay Dark Logo"
                        >
                          <span className="icon is-large has-text-white">
                            <DownloadIcon />
                          </span>
                        </a>
                        <img src={TwispayWhiteLogo} alt="Twispay Dark Logo" />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="is-size-5 has-text-centered">
                  Download Logo Kit (
                  {kits.allFile.edges.map((kit, i) => (
                    <>
                      <a
                        key={kit.node.name}
                        href={kit.node.publicURL}
                        download={kit.node.name}
                        className="has-text-primary"
                      >
                        {kit.node.name
                          .split("twispay-logo-kit-")[1]
                          .toUpperCase()}
                        {i !== kits.allFile.edges.length - 1 ? ", " : ""}
                      </a>
                    </>
                  ))}
                  )
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LogosResourcesPage
